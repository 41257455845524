import React, { useEffect } from 'react'

export default function TermsCondition() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
    return (
        <div>
            <div className="Main">
                <div className="TermsConditionsArea">
                    <div className="container">
                        <div className="section-title">
                            <h2>Terms and Conditions</h2>
                        </div>
                        <div className="TermsContent">
                            <p>
                                Welcome to Zula Technologies Limited (“Zulachat”, “we”, “us”, or
                                “our”). These Terms and Conditions (“Terms”) govern your use of the
                                Zulachat landing page (
                                <a
                                    href="https://zulachat.com/"
                                    target="_blank"
                                    style={{ color: "#E94057", textDecoration: "underline" }}
                                >
                                    https://zulachat.com
                                </a>
                                ) where we collect certain information about you. By accessing and
                                using our landing page, you agree to comply with these Terms. If you
                                do not agree with these Terms, please do not use the website.
                            </p>
                            <h4>1) Information We Collect</h4>
                            <p>
                                When you submit your details on the Zulachat landing page, we collect
                                the following information:
                            </p>
                            <ul>
                                <li>Your full name</li>
                                <li>Your email address</li>
                                <li>Your social media username</li>
                                <li>
                                    The number of followers associated with your social media account
                                </li>
                                <li>
                                    The social media platform where your account is based (e.g.,
                                    Instagram, Twitter, TikTok)
                                </li>
                            </ul>
                            <p>
                                By providing this information, you agree that it will be stored and
                                processed by Zula Technologies Limited in accordance with our Privacy
                                Policy.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>2) Use of Collected Information</h4>
                            <p>The information you provide will be used to:</p>
                            <ul>
                                <li>Evaluate and review your social media account.</li>
                                <li>Contact you regarding your submission.</li>
                                <li>
                                    Communicate future updates, promotions, or opportunities related to
                                    Zulachat.
                                </li>
                            </ul>
                            <p>
                                We will not share, sell, or otherwise disclose your personal
                                information to third parties, except as stated in our Privacy Policy.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>3) User Obligations</h4>
                            <p>By submitting your details, you agree:</p>
                            <ul>
                                <li>To provide accurate, complete, and current information.</li>
                                <li>
                                    Not to impersonate any other individual or provide misleading
                                    information.
                                </li>
                                <li>
                                    That you are the owner or authorized user of the social media
                                    account you submit.
                                </li>
                            </ul>
                        </div>
                        <div className="TermsContent">
                            <h4>Eligibility</h4>
                            <p>
                                You must be at least 18 years of age to submit your information
                                through the Zulachat landing page. By providing your details, you
                                confirm that you meet this age requirement.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>5) Account Termination</h4>
                            <p>
                                Zulachat reserves the right to reject any submission or remove your
                                information at any time, for any reason, without notice. This includes
                                cases where you breach these Terms or submit false or misleading
                                information.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>6) Limitation of Liability</h4>
                            <p>
                                To the fullest extent permitted by law, Zula Technologies Limited will
                                not be liable for any direct, indirect, incidental, or consequential
                                damages arising from your use of this landing page, or from the
                                collection, use, or inability to use your submitted information.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>7) Indemnification</h4>
                            <p>
                                You agree to indemnify and hold harmless Zula Technologies Limited,
                                its affiliates, and their respective directors, officers, employees,
                                and agents from any claims, liabilities, damages, losses, or expenses,
                                including legal fees, arising from your use of this landing page, or
                                any breach of these Terms.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>8) Intellectual Property</h4>
                            <p>
                                All content on the Zulachat landing page, including text, design,
                                graphics, logos, and software, is the intellectual property of Zula
                                Technologies Limited. You may not copy, modify, or distribute any part
                                of our website without express written permission.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>9) Changes to the Terms</h4>
                            <p>
                                Zula Technologies Limited reserves the right to modify or update these
                                Terms at any time. We will notify users of significant changes by
                                posting the updated Terms on our website. Your continued use of the
                                landing page after such updates means you accept the revised Terms.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>10) Governing Law and Jurisdiction</h4>
                            <p>
                                These Terms are governed by the laws of Gibraltar, and any disputes
                                will be subject to the exclusive jurisdiction of the courts of
                                Gibraltar.
                            </p>
                        </div>
                        <div className="TermsContent">
                            <h4>11) Contact Us</h4>
                            <p>
                                If you have any questions or concerns regarding these Terms, please
                                contact us at:
                            </p>
                            <p>
                                Zula Technologies Limited
                                <br />
                                Suite 5, 39 Irish Town, Gibraltar, GX11 1AA
                                <br />
                                Email: zulachat1@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
