import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export const Footer = ({ onScrollToWhyZulaChat }) => {
    const navigate = useNavigate();

    const goToHomeSection = (section) => {
        navigate('/', { state: { section } });
    };
    return (
        <div>
            <footer className="footer-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <div className="footer-brand">
                                <Link to="/">
                                    <img src={require('../images/footer/Group 336.png')} />
                                </Link>
                                <p>
                                    Whether you're a big name, a small creator, or an up-and-coming
                                    influencer, it's worth a try to apply to Zulachat. This is the
                                    platform to amplify your reach, engage with your audience, and,
                                    most importantly, monetize your following. If you're accepted,
                                    you'll receive an exclusive invite via email.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-1 col-md-12">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="company-list">
                                        <h5>Company</h5>
                                        <ul className="list-unstyled">
                                            <li>
                                                <a href='javascript:void(0)' className="text-white"  onClick={() => goToHomeSection('sectionName')}>
                                                    Why Zulachat
                                                </a>
                                            </li>
                                            <li>
                                                <Link to='privacy-policy' className="text-white">
                                                    Privacy Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='terms-condition' className="text-white">
                                                    Terms &amp; Conditions
                                                </Link>
                                            </li>
                                            <li>
                                                <a
                                                    href="mailto:zulachat1@gmail.com"
                                                    className="text-white"
                                                >
                                                    Contact Us
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="follow-list">
                                        <h5>Follow Us</h5>
                                        <div className="social-icons">
                                            <a href="https://www.instagram.com/zulachat_/profilecard/?igsh=bnY5emRkdHJ4a250" target="_blank">
                                                <i className="fa-brands fa-instagram" />
                                            </a>
                                            <a href="https://www.tiktok.com/@ialacl?_t=8qqCulPB7B3&_r=1" target="_blank">

                                                <i className="fa-brands fa-tiktok" />
                                            </a>
                                            <a href="https://youtube.com/@zulachat?si=5EpEXVVxLPS-ISN9" target='_blank'>
                                                <i className="fa-brands fa-youtube" />
                                            </a>
                                            <a href="https://www.facebook.com/profile.php?id=61560809914317" target='_blank'>
                                                <i className="fa-brands fa-facebook" />
                                            </a>
                                            <a href="https://www.linkedin.com/posts/zulachat_marketing-innovation-zulachat-ugcPost-7232689004775657472-f9Fi?utm_source=share&utm_medium=member_ios" target='_blank'>
                                                <i className="fa-brands fa-linkedin" />
                                            </a>
                                        </div>
                                        <h5>Address</h5>
                                        <p>
                                            Suite 5, 39 Irish Town,
                                            <br /> GX11 1AA, Gibraltar.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <h5>Get the app</h5>
                                    <div className="app-buttons">
                                        {/* <a href="#" class="btn btn-outline-light d-block mb-3"> */}
                                        <img
                                            src={require('../images/footer/Link.png')}
                                            className="me-2"
                                            alt="Apple Store"
                                        />
                                        {/* </a> */}
                                        {/* <a href="#" class="btn btn-outline-light d-block"> */}
                                        <img
                                            src={require('../images/footer/Link (1).png')}
                                            className="me-2"
                                            alt="Google Play"
                                        />
                                        {/* </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col text-center">
                            <p className="mb-0">Copyright © 2024. Zulachat </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
